<template>
  <div class="content">
    <div class="holder holder--between holder--indent">
      <div class="content__party">
        <router-link
          :to="{ name: 'partner-discounts' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--color">Редактирование скидки (ID: {{ this.$route.params.id }})</h1>
      </div>
      <button @click="deleteDiscount($route.params.id)" class="button button--light button--no-indent button--circle">
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#2b93e7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5542 5.0329c-.2098-.1602-.5355-.143-.7274.0383L8 6.7984 6.173 5.0712c-.1918-.1814-.5175-.1985-.7273-.0383-.2099.1602-.2245.4372-.0326.6186l1.9 1.7963L5.058 9.58c-.1919.1814-.1773.4583.0326.6186.2098.1602.5355.1431.7273-.0383L8 8.0972l2.1822 2.0631c.1918.1814.5175.1985.7273.0383.2099-.1603.2245-.4372.0326-.6186L8.6868 7.4478l1.9-1.7963c.1919-.1814.1773-.4584-.0326-.6186z" fill="#fff"/></svg>
        <span>Удалить скидку</span>
      </button>
    </div>
    <form class="form" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Тип</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Скидка</div>
                <input type="radio" name="discount" v-model.number="form.type" value="1"/>
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Спецпредложение</div>
                <input type="radio" name="discount" v-model.number="form.type" value="2"/>
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>Обложка</label>
            <cropper-modal
              @clearPhoto="clearPhoto"
              :getCropCoords="getCropCoords"
              :getCropHash="getCropHash"
              :originalImgUrlProp="photo_url"
              :croppedImgUrlProp="crop_url"
              :stencilProps="{ aspectRatio: 1 }"
              :square="true"
              ref="cropperModal"
            />
          </div>
          <div class="form-group">
            <label for="desc">Описание скидки (RU) <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.desc.$error }">
                  <textarea
                    id="desc"
                    name="desc"
                    class="form-control__textarea"
                    v-model.trim="form.desc"
                    placeholder="Введите комментарий"
                    @change="$v.form.desc.$touch()"
                  >
                  </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.desc.$dirty && !$v.form.desc.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group">
            <label for="desc_en">Описание скидки (EN) <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.desc_en.$error }">
                  <textarea
                    id="desc_en"
                    name="desc_en"
                    class="form-control__textarea"
                    v-model.trim="form.desc_en"
                    placeholder="Введите комментарий"
                    @change="$v.form.desc_en.$touch()"
                  >
                </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.desc_en.$dirty && !$v.form.desc_en.enAlpha">
              Принимаются только английские буквы алфавита
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <uploader :getFileHash="getFileHash" limit-files="10" :fileUrl="fileUrl" :uploadedFiles="uploadedPhotos"
                      @remove-file="removeFile"/>
          </div>
          <div class="form-group">
            <label for="title">Заголовок скидки (RU) <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.title.$error }">
              <input
                id="title"
                type="text"
                class="form-control__input"
                name="title"
                placeholder="Введите название"
                v-model.trim="form.title"
                @change="$v.form.title.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.title.$dirty && !$v.form.title.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.title.$dirty && !$v.form.title.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group">
            <label for="title_en">Заголовок скидки (EN) <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.title_en.$error }">
              <input
                id="title_en"
                type="text"
                class="form-control__input"
                name="title_en"
                placeholder="Введите название"
                v-model.trim="form.title_en"
                @change="$v.form.title_en.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.title_en.$dirty && !$v.form.title_en.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.title_en.$dirty && !$v.form.title_en.enAlpha">
              Принимаются только английские буквы алфавита
            </div>
          </div>
          <div v-if="form.type === 1" class="form-group">
            <label for="size">Размер скидки <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.size.$error }">
              <input
                id="size"
                type="number"
                class="form-control__input"
                name="size"
                placeholder="Введите размер скидки"
                v-model.trim="form.size"
                @change="$v.form.size.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.size.$dirty && !$v.form.size.required">Обязательное поле</div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.date_start.$error }">
            <label>Начало действия <sup>*</sup></label>
            <date-picker
              v-model="form.date_start"
              name="dob"
              format="DD.MM.YYYY HH:mm"
              value-type="DD.MM.YYYY HH:mm"
              type="datetime"
              placeholder="дд.мм.гггг чч:мм"
              :editable="true"
              ref="datepicker"
              :class="{ 'mx-datepicker--error': $v.form.date_start.$error }"
              title-format="DD.MM.YYYY"
              time-title-format="DD.MM.YYYY"
            >
              <template #input="{ props, event }">
                <masked-input mask="11.11.1111 11:11" :value="props.value" v-bind="props" v-on="event" v-model="form.date_start"/>
              </template>
            </date-picker>
            <div class="form-group__error" v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label>Окончание действия</label>
            <date-picker
              v-model="form.date_end"
              name="dob"
              format="DD.MM.YYYY HH:mm"
              value-type="DD.MM.YYYY HH:mm"
              type="datetime"
              placeholder="дд.мм.гггг чч:мм"
              :editable="true"
              ref="datepicker"
              title-format="DD.MM.YYYY"
              time-title-format="DD.MM.YYYY"
            >
              <template #input="{ props, event }">
                <masked-input mask="11.11.1111 11:11" :value="props.value" v-bind="props" v-on="event" v-model="form.date_end"/>
              </template>
            </date-picker>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, helpers, requiredIf} from 'vuelidate/lib/validators';
import { omit } from 'lodash';

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i);
const enAlpha = helpers.regex('enAlpha', /[a-zA-Z]/i);

import CropperModal from '@/components/Cropper';
import Uploader from '@/components/Uploader';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'DiscountsDetail',
  mixins: [validationMixin],
  components: {
    CropperModal,
    Uploader,
    DatePicker,
    MaskedInput,
  },
  validations: {
    form: {
      title: {required, ruAlpha},
      title_en: {required, enAlpha},
      desc: {required, ruAlpha},
      desc_en: {required, enAlpha},
      size: { required: requiredIf(function () {
        return this.form.type === 1;
      }) },
      date_start: {required},
    }
  },
  data() {
    return {
      form: {
        coords: {},
        photos: [],
        photo: '',
        title: '',
        title_en: '',
        desc: '',
        desc_en: '',
        size: '',
        date_start: '',
        date_end: '',
        type: '',
      },
      photo_url: '',
      crop_url: '',
      uploadedPhotos: [],
      fileUrl: `${process.env.VUE_APP_PARTNER_URL}files`,
    };
  },
  mounted() {
    this.fetchDiscounts();
  },
  methods: {
    fetchDiscounts() {
      this.$store.dispatch('partner/discount/GET_DATA', this.$route.params.id)
        .then(response => {
          this.form = {...this.form, ...response.data};
          this.uploadedPhotos = [...response.data.photos];
          const ids = [];
          this.form.photos.forEach(photo => {
            if (typeof photo === 'object') ids.push(photo.id);
          });
          this.form.photos = ids;
          this.phoneValue = response.data.phone ?? '';
          this.photo_url = response.data.photo_url;
          this.crop_url = response.data.crop_url;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (this.form.coords.hasOwnProperty('width') && !this.form.photo) {
          this.$refs.cropperModal.sendAdminCrop()
            .then(() => {
              this.sendForm();
            });
        } else {
          this.sendForm();
        }
      }
    },
    sendForm() {
      if (!this.form.date_end) {
        this.form = omit(this.form, ['date_end']);
      }
      if (this.form.type === 2) {
        this.form = omit(this.form, ['size']);
      }
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('partner/discount/PATCH_DATA', {id: this.$route.params.id, data: this.form})
        .then(response => {
          this.$refs.submit.classList.remove('preload');
          if (response.status >= 400) {
            for (const key in response.data) {
              if (typeof response.data[key] === 'string') {
                this.$notify({
                  type: 'error',
                  text: response.data[key]
                });
              } else {
                this.$notify({
                  type: 'error',
                  text: response.data[key][0]
                });
              }
            }
          } else {
            this.$notify({
              type: 'success',
              title: 'Уведомление',
              text: 'Данные успешно сохранены'
            });
            this.$router.push({name: 'partner-discounts'});
          }
        })
        .catch(response => {
          this.$refs.submit.classList.remove('preload');
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    clearPhoto() {
      this.form.photo = '';
    },
    getFileHash(value) {
      this.form.photos.push(value);
    },
    getCropCoords(value) {
      this.form.coords = value;
    },
    getCropHash(value) {
      this.form.photo = value;
    },
    removeFile(index) {
      this.uploadedPhotos.splice(index, 1);
      this.form.photos.splice(index, 1);
    },
    deleteDiscount(id) {
      this.$store.dispatch('partner/discount/DELETE_DATA', id)
        .then((response) => {
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Скидка успешно удалена'
          });
          this.$router.push({ name: 'partner-discounts' });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка удаления скидки'
          });
        });
    }
  }
};
</script>
